/* You can add global styles to this file, and also import other style files */
@import "~bootstrap/dist/css/bootstrap.min.css";
.sidenav {
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  width: 250px;
  //   z-index: 1000;
  background-color: #0c4364;
}
.spinner-overlay {
  position: absolute; /* Ensure it covers the whole screen */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  z-index: 9999;
}

.spinner-container {
  height: 100%; /* Changed from 100vh to 100% */
  display: flex;
  justify-content: center;
  align-items: center;
}
.main-content {
  margin-left: 250px;
  //   padding: 20px;
}

.rounded1 {
  border-radius: 4px;
}
.rounded2 {
  border-radius: 8px;
}
.rounded3 {
  border-radius: 12px;
}
.rounded4 {
  border-radius: 16px;
}
.bg-white {
  color: #ffff;
}
.bg-grey {
  color: #f7f8fb;
}
.bg-blue-1 {
  background-color: #0c4364;
}
.bg-green {
  background-color: #16b367;
}
.bg-blue {
  background-color: #5c6bc0;
}
.text-cyan {
  color: #16b3af;
}
.text-green {
  color: #16b367;
}
.text-white {
  color: #ffff;
}
.text-blue {
  color: #162e4d;
}
.box {
  box-shadow: 4px 8px 8px #d9d9e8ca;
  border-radius: 10px;
}
.button {
  background-color: #5c6bc0;
  border: none;
  border-radius: 0px;
}

.button-red {
  background-color: red;
  border: none;
  border-radius: 0px;
}
button {
  background-color: #5c6bc0;
  border: none;
  border-radius: 0px;
  color: #ffffff;
  padding: 5px;
  min-width: 144px;
  text-wrap: nowrap;
  max-width: fit-content;
}
.font12 {
  font-size: 12px;
}
.font16 {
  font-size: 16px;
}
.font24 {
  font-size: 24px;
}
select {
  min-width: 5rem;
  color: #16b3af;
}

.chnageonhover:hover {
  background-color: rgb(211, 241, 241) !important;
}

.chnageonhover {
  background-color: #f9f9f9 !important;
}

.text-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 20vw;
}

.border-primary {
  border: 1px solid;
  border-color: #d0d2f4 !important;
}

.alternate-row {
  background-color: #162e4d !important;
}
.lower-border {
  border-bottom: 1px solid;
  border-color: #d0d2f4 !important;
}

.form-control {
  border-radius: 0px !important;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.fw-800 {
  font-weight: 800;
}

.fw-900 {
  font-weight: 900;
}

/* Modal styling */
.modal {
  display: block; /* Show the modal */
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Black with opacity */
}

.modal-content {
  background-color: #fefefe;
  margin: 10% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%; /* Adjust as needed */
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

/* Pin Grid Layout */
.pin-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr); /* 5 columns */
  grid-auto-rows: minmax(30px, auto); /* Define row height */
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.pin-grid div {
  border: 1px solid #ccc;
  padding: 5px;
  text-align: center;
}

/* Add Button */
.add-button {
  margin-top: 20px;
  text-align: center;
}
